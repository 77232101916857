/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.navbar-fixed-bottom {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 14;
  width: 100%;
  bottom: 0;
  margin-bottom: 0;
  border-width: 1px 0 0;
  border-radius: 0;
  background-color: white;
  height: 40px;
}
.fc-daygrid-event-harness{
  cursor: pointer;
}
.fc-daygrid-dot-event.fc-event-today .fc-event-title{
  color:#0c68f0;
}
.fc-day-other .fc-daygrid-day-number{
color: #747476;
}
.fc .fc-day-other .fc-daygrid-day-top{
  opacity: 1;;
}
  .fc-daygrid-dot-event .fc-event-title{
    word-break: break-word;
    white-space: normal;
  }
  .ag-keyboard-focus .ag-paging-button:focus::after{
    border-color:black;
    outline: 5px auto #0D47A1;
  }
  #navigation-announcer{
    position: absolute;
    top: 0;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
  .fc .fc-toolbar{
flex-wrap: wrap;
  }
 .url_link{
  color:#003FC1
 }
 .fc-daygrid-dot-event .fc-event-time{
  display:none;
 }

.fc-event-time, .fc-v-event .fc-event-title {
    color: #000000;
}