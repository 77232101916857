.header-style {
    background-color: rgb(94,156,210)
  }

  .ag-header-cell-label {
    justify-content: center;
 }

 .ag-header-active {
  background-color: transparent;
}
/* High contrast mode focus style */
@media (forced-colors: active) {
    .ag-theme-alpine .ag-cell:focus,
    .ag-theme-alpine .ag-header-cell:focus {
        outline: 3px solid #2196f3 !important; /*theme wise focus outline color will display for contrast mode*/
        outline-offset: 2px; 
    }
}