.gridStyle {
    width: "100%";
    padding-top: "20px";
    padding-right: "0px";
    border-radius: 90;
    margin-top: 20;
    margin-bottom:20;
  }

  .buttonContainerStyle {
    display: flex;
    flex-wrap: wrap;
    gap: "var(--he-spacing-x-small)";
    margin-bottom: "var(--he-spacing-large)";
  };

  /* .container {
    margin-bottom: 50px;
  }; */

  .pageSizeStyle {
    color: "#1861ac",
  };

  